import { ActionIcon, Space, TextInput, TextInputProps } from '@mantine/core';
import { ChangeEvent, forwardRef, useContext } from 'react';

import { TbX } from 'react-icons/tb';
import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

type BaseCottageTextInputProps = Omit<TextInputProps, 'value' | 'onChange'>;

type CottageTextInputPropsWithClearable = BaseCottageTextInputProps & {
  clearable: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type CottageTextInputPropsWithoutClearable = BaseCottageTextInputProps & {
  clearable?: never;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type CottageTextInputProps =
  | CottageTextInputPropsWithClearable
  | CottageTextInputPropsWithoutClearable;

/**
 * @returns Mantine TextInput that defaults to size medium on small screen
 * sizes.
 */
export const CottageTextInput = forwardRef<
  HTMLInputElement,
  CottageTextInputProps
>(({ clearable, value, onChange, ...props }: CottageTextInputProps, ref) => {
  const { layout } = useContext(LayoutContext);

  const handleClear = () => {
    if (onChange) {
      const event = {
        target: { value: '' },
      } as ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  };

  const getClearButton = () => {
    const clearButton = (
      <ActionIcon
        onClick={handleClear}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <TbX size={16} />
      </ActionIcon>
    );

    if (clearable) {
      if (value) {
        return clearButton;
      }

      // Invisible placeholder to maintain consistent width
      return <Space w="md" />;
    }

    return null;
  };

  return (
    <TextInput
      ref={ref}
      size={layout === LayoutType.MOBILE ? 'md' : undefined}
      value={value}
      onChange={onChange}
      rightSection={getClearButton()}
      {...props}
    />
  );
});
CottageTextInput.displayName = 'CottageTextInput';
