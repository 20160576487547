import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { forwardRef, useContext } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

export type CottagePasswordInputProps = PasswordInputProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

/**
 * @returns Mantine PasswordInput that defaults to size medium on small screen
 * sizes.
 */
export const CottagePasswordInput = forwardRef<
  HTMLInputElement,
  CottagePasswordInputProps
>(({ ...props }: CottagePasswordInputProps, ref) => {
  const { layout } = useContext(LayoutContext);

  return (
    <PasswordInput
      ref={ref}
      size={layout === LayoutType.MOBILE ? 'md' : undefined}
      {...props}
    />
  );
});
CottagePasswordInput.displayName = 'CottagePasswordInput';
