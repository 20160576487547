import { Avatar, AvatarProps } from '@mantine/core';
import { TbUserCircle } from 'react-icons/tb';

import { CottageTooltip } from '@/components/shared/default/CottageTooltip';
import { Role } from '@/lib/constants/role';
import { getRole } from '@/lib/roleUtils';
import { getInitials } from '@/lib/stringUtils';

export type ProfileAvatarProps = {
  profilePictureName?: string | null;
  roles?: string[];
  userName?: string | null;
  displayNameTooltip?: boolean;
} & AvatarProps;

const getAvatarColor = (roles: string[]) => {
  const role = getRole(roles);

  let color;

  switch (role) {
    case Role.CUSTOMER:
      color = 'cyan';
      break;
    case Role.CONSULTANT:
      color = 'grape.9';
      break;
    case Role.GC:
      color = 'teal.7';
      break;
    case Role.INTERNAL:
      color = 'gray.6';
      break;
    default:
      color = 'orange';
  }
  return color;
};

export default function ProfileAvatar({
  profilePictureName,
  roles = [],
  size = 'md',
  userName,
  displayNameTooltip,
  ...otherProps
}: ProfileAvatarProps) {
  let avatar;
  if (profilePictureName) {
    avatar = (
      <Avatar
        size={size}
        radius="xl"
        src={profilePictureName}
        alt="Profile image"
        {...otherProps}
      />
    );
  } else if (userName) {
    const nameInitials = getInitials(userName);
    avatar = (
      <Avatar
        size={size}
        radius="xl"
        variant="filled"
        color={getAvatarColor(roles)}
        alt={nameInitials}
        {...otherProps}
      >
        {nameInitials}
      </Avatar>
    );
  } else {
    // Mantine's default placeholder avatar
    avatar = (
      <Avatar size={size} radius="xl" alt="generic user icon" {...otherProps}>
        <TbUserCircle />
      </Avatar>
    );
  }

  return displayNameTooltip && !!userName ? (
    <CottageTooltip label={userName}>{avatar}</CottageTooltip>
  ) : (
    avatar
  );
}
