import { DateInput, DateInputProps } from '@mantine/dates';
import { forwardRef, useContext } from 'react';
import { TbCalendar } from 'react-icons/tb';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

export type CottageDateInputProps = DateInputProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size' | 'value' | 'onChange'>;

/**
 * @returns Mantine DateInput that defaults to size medium on small screen
 * sizes.
 */
export const CottageDateInput = forwardRef<
  HTMLInputElement,
  CottageDateInputProps
>(({ ...props }: CottageDateInputProps, ref) => {
  const { layout } = useContext(LayoutContext);

  return (
    <DateInput
      valueFormat="MM/DD/YYYY"
      icon={<TbCalendar />}
      firstDayOfWeek={0}
      ref={ref}
      size={layout === LayoutType.MOBILE ? 'md' : undefined}
      {...props}
    />
  );
});
CottageDateInput.displayName = 'CottageDateInput';
