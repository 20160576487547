import { Tooltip, TooltipProps } from '@mantine/core';

export type CottageTooltipProps = TooltipProps & {
  innerDivProps?: JSX.IntrinsicElements['div'];
};

export const CottageTooltip = ({
  children,
  innerDivProps,
  ...tooltipProps
}: CottageTooltipProps) => {
  if (!tooltipProps.label) {
    return (
      <div style={{ display: 'inline-block', ...innerDivProps?.style }}>
        {children}
      </div>
    );
  }

  return (
    <Tooltip
      multiline
      position="bottom"
      events={{ hover: true, focus: true, touch: true }}
      withArrow
      {...tooltipProps}
    >
      {/* <Tooltip> has problems attaching directly to some elements (e.g. SVG 
      icons) and this <div> here helps prevent those problems in such cases.*/}
      <div
        data-testid="tooltip-target"
        {...innerDivProps}
        style={{ display: 'inline-block', ...innerDivProps?.style }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
