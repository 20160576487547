import { Button, ButtonProps, createStyles, Variants } from '@mantine/core';
import { forwardRef } from 'react';

const useStyles = createStyles(
  (
    theme,
    {
      variant,
    }: {
      variant?: Variants<'default'>;
    }
  ) => ({
    root: {
      ...(variant === 'default' && {
        border: `2px solid ${theme.colors[theme.primaryColor][8]}`,
      }),
    },
  })
);

export type CottageButtonProps = React.ComponentPropsWithoutRef<'button'> &
  ButtonProps;

/**
 * @returns Mantine Button that defaults to size small on small screen sizes.
 */
export const CottageButton = forwardRef<HTMLButtonElement, CottageButtonProps>(
  ({ variant, ...props }: CottageButtonProps, ref) => {
    const { classes } = useStyles({ variant });

    return (
      <Button
        ref={ref}
        size="sm"
        classNames={{
          root: classes.root,
        }}
        variant={variant}
        {...props}
      />
    );
  }
);
CottageButton.displayName = 'CottageButton';
