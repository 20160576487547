/**
 * NAMING CONVENTION: Object + Past Tense Verb
 * Events are case sensitive. Each word should be capitalized.
 */
export const ANALYTICS_EVENTS = {
  DOWNLOAD_DOCUMENT_BUTTON_CLICKED: 'Download Document Button Clicked',
  ADD_DOCUMENT_BUTTON_CLICKED: 'Add Document Button Clicked',
  ADD_NEW_SCOPE_TO_WORK_ORDER_CLICKED: 'Add New Scope To Work Order Clicked',
  ADD_PAYMENT_BUTTON_CLICKED: 'Add Payment Button Tapped',
  AGREEMENT_ACTION_REQUIRED_CLICKED: 'Agreement Action Required Clicked',
  AGREEMENT_APPROVE_BUTTON_CLICKED: 'Agreement Approve Button Clicked',
  AGREEMENT_MODAL_PAY_INVOICE_BUTTON_CLICKED:
    'Agreement Modal Pay Invoice Button Clicked',
  AGREEMENT_DOWNLOAD_COPY_BUTTON_CLICKED:
    'Agreement Download Copy Button Clicked',
  AGREEMENT_VIEWED: 'Agreement Viewed',
  AGREEMENT_VOID_BUTTON_CLICKED: 'Agreement Void Button Clicked',
  ATTACHMENT_TAPPED: 'Attachment Tapped',
  ANNOUNCEMENT_MODAL_CLOSED: 'Announcement Modal Closed',
  BANK_ACCOUNT_CONNECTED: 'Bank Account Connected',
  BANK_ACCOUNT_CONNECTION_FAILED: 'Bank Account Connection Failed',
  BREADCRUMB_CLICKED: 'Breadcrumb Clicked',
  CHANGE_ORDER_ACCEPTED_CUSTOMER: 'Change Order Accepted By Customer',
  CHANGE_ORDER_ACTION_REQUIRED_CLICKED: 'Change Order Action Required Clicked',
  CHANGE_ORDER_ACTIVE_ROW_CLICKED: 'Change Order Active Row Clicked',
  CHANGE_ORDER_APPROVE_BUTTON_CLICKED: 'Change Order Approve Button Clicked',
  CHANGE_ORDER_CREATE_NEW_VIEWED: 'Create Change Order Page Viewed',
  CHANGE_ORDER_SEND_CHANGE_ORDER_TO_HO_CLICKED:
    'Send Change Order To HO Clicked',
  CHANGE_ORDER_VIEWED: 'Change Order Viewed',
  CHANGE_ORDER_VOIDED: 'Change Order Voided',
  CHANGE_ORDER_CHANGE_REQUESTED_BY_CUSTOMER:
    'Change Order Change Requested By Customer',
  CHANGE_ORDER_CHANGE_REQUESTED_BY_GC: 'Change Order Change Requested By GC',
  CHECK_INVOICE_CREATED: 'Check Invoice Created',
  CLOSE_OUT_PROJECT_BUTTON_CLICKED: 'Close Out Project Button Clicked',
  CONFIRM_CHECK_SENT_BUTTON_CLICKED: 'Confirm Check Sent Button Clicked',
  CONNECT_BANK_ACCOUNT_BUTTON_CLICKED: 'Connect Bank Account Button Tapped', // GCs and Consultants
  CONSTRUCTION_KICKOFF_COMPLETE_BUTTON_CLICKED:
    'Construction Kickoff Complete Button Clicked',
  CONSTRUCTION_KICKED_OFF_MODAL_BUTTON_CLICKED:
    'Construction Kicked Off Modal Button Clicked',
  CONSTRUCTION_KICKOFF_DRAFT_SAVED: 'Construction Kickoff Draft Saved',
  CONSTRUCTION_CLOSEOUT_TASK_ROW_CLICKED:
    'Construction Closeout Task Row Clicked',
  CONSTRUCTION_CLOSEOUT_GC_FEEDBACK_MODAL_CLOSED:
    'Construction Closeout GC Feedback Modal Closed',
  CONSTRUCTION_CLOSEOUT_CUSTOMER_FEEDBACK_MODAL_CLOSED:
    'Construction Closeout Customer Feedback Modal Closed',
  CONSTRUCTION_KICKOFF_TASK_ROW_CLICKED:
    'Construction Kickoff Task Row Clicked',
  CONSTRUCTION_KICKOFF_VIEWED: 'Construction Kickoff Viewed',
  CREATE_INVOICE_BUTTON_CLICKED: 'Create Invoice Button Tapped',
  CREATE_PROPOSAL_BUTTON_CLICKED: 'Create Proposal Button Tapped',
  CREATE_CHANGE_ORDER_BUTTON_CLICKED: 'Create Change Order Button Tapped',
  CREATE_WORK_ORDER_BUTTON_CLICKED: 'Create Work Order Button Tapped',
  CREATE_REFERRAL_BUTTON_CLICKED: 'Create Referral Button Tapped',
  CSAT_FEEDBACK_CUSTOMER_SUBMITTED: 'CSAT Feedback Customer Submitted',
  CSAT_FEEDBACK_GC_SUBMITTED: 'CSAT Feedback GC Submitted',
  SEND_GC_CUSTOMER_FEEDBACK_DECLINED: 'Send GC Customer Feedback Declined',
  DESIGN_PACKET_BUTTON_CLICKED: 'Design Packet Button Clicked',
  DOCUMENT_STORAGE_ANNOUNCEMENT_CLOSED: 'Document Storage Announcement Closed',
  DIGITALOCEAN_TO_GOOGLE_DRIVE_UPLOAD_FAILED:
    'DigitalOcean to Google Drive Upload Failed',
  DOCUMENT_ADD_NEW_VERSION_BUTTON_CLICKED:
    'Document Add New Version Button Clicked',
  DOCUMENTS_DELETE_VERSIONS_MODAL_CLOSED:
    'Document Delete Versions Modal Closed',
  DOCUMENTS_DELETE_VERSIONS_MODAL_OPENED:
    'Document Delete Versions Modal Opened',
  DOCUMENT_IMPORT_NEW_FILE_CLICKED: 'Document Import New File Clicked',
  DOCUMENT_UPLOAD_FORM_CLOSED: 'Document Upload Form Closed',
  DOCUMENTS_MENU_OPENED: 'Documents Menu Opened',
  DOCUMENTS_VIEW_ALL_VERSIONS_MODAL_CLOSED:
    'Documents View All Versions Modal Closed',
  DOCUMENTS_VIEW_ALL_VERSIONS_OPTION_CLICKED:
    'Documents View All Versions Option Clicked',
  DOCUMENTS_VIEW_DETAILS_MODAL_CLOSED: 'Documents View Details Modal Closed',
  DOCUMENTS_VIEW_DETAILS_OPTION_CLICKED:
    'Documents View Details Option Clicked',
  DOCUMENTS_MOBILE_FILTER_BUTTON_CLICKED:
    'Documents Mobile Filter Button Clicked',
  DOCUMENTS_RENAME_MODAL_CLOSED: 'Documents Rename Modal Closed',
  DOCUMENTS_RENAME_MODAL_OPENED: 'Documents Rename Modal Opened',
  DOCUSIGN_ENVELOPE_CREATION_FAILED: 'DocuSign Envelope Creation Failed',
  EDIT_EST_COMPLETION_DATE_CLICKED: 'Edit Est Completion Date Clicked',
  EDIT_PAYMENT_DETAILS_TAPPED: 'Edit Payment Details Tapped',
  EXTEND_DUE_DATE_BUTTON_CLICKED: 'Extend Due Date Button Clicked',
  ERROR_VIEWED: 'Error Viewed',
  FINISHES_BUTTON_CLICKED: 'Finishes Button Clicked',
  FORGOT_PASSWORD_ERROR: 'Forgot Password Error',
  FORGOT_PASSWORD_LINK_CLICKED: 'Forgot Password Link Tapped',
  HOME_PAGE_VIEWED: 'Home Page Viewed',
  INVOICE_CREATED: 'Invoice Created',
  INVOICE_CREATION_FAILED: 'Invoice Creation Failed',
  INVOICE_DOWNLOAD_INVOICE_BUTTON_CLICKED:
    ' Invoice Download Invoice Button Clicked',
  INVOICE_PAID: 'Invoice Paid',
  INVOICE_PAYMENT_FAILED: 'Invoice Payment Failed',
  INVOICE_VIEWED: 'Invoice Viewed',
  INVOICE_VOIDED: 'Invoice Voided',
  INVOICES_PAGE_VIEWED: 'Invoices Page Viewed',
  LEARN_MORE_ABOUT_COMPANY_CLICKED: 'Learn More About Company Clicked',
  LEARN_MORE_ABOUT_GC_COMPANY_CLICKED: 'Learn More About GC Company Clicked',
  LOST_CONTRACT_BANNER_CLOSED: 'Lost Contract Banner Closed',
  OUTSTANDING_INVOICE_ROW_CLICKED: 'Outstanding Invoice Row Clicked',
  PASSWORD_RESET_REQUESTED: 'Password Reset Requested',
  PAY_INVOICE_BUTTON_CLICKED: 'Pay Invoice Button Tapped',
  PAYMENT_CONFIRMATION_MODAL_CANCEL_BUTTON_CLICKED:
    'Payment Confirmation Modal Cancel Button Clicked',
  PAYMENT_METHODS_SELECT_CLICKED: 'Payment Methods Select Clicked',
  PAYMENT_OPTIONS_MODAL_DOWNLOAD_STRIPE_INVOICE_PDF:
    'Payment Options Modal Download Stripe Invoice Pdf',
  PAYMENT_OPTIONS_MODAL_PAY_BY_CHECK_CLICKED:
    'Payment Options Modal Pay By Check Clicked',
  PAYMENT_OPTIONS_PAY_BY_CHECK_VIEW_INSTRUCTIONS_CLICKED:
    'Payment Options Pay By Check View Instructions Clicked',
  PROCUREMENT_QUOTE_REQUEST_PAGE_VIEWED:
    'Procurement Quote Request Page Viewed',
  PROCUREMENT_PAGE_EMPTY_RESPONSE: 'Procurement Page Empty Response',
  PROCUREMENT_PAGE_QUOTE_REQUEST_BUTTON_CLICKED:
    'Procurement Page Quote Request Button Clicked',
  PROCUREMENT_PAGE_TAB_CLICKED: 'Procurement Page Tab Clicked',
  PROCUREMENT_PAGE_VIEWED: 'Procurement Page Viewed',
  PROFILE_ICON_CLICKED: 'Profile Icon Tapped',
  PROJECT_DOCUMENTS_BUTTON_CLICKED: 'Project Documents Button Clicked',
  PROJECT_VIEWED: 'Project Viewed',
  PROJECTS_PAGE_VIEWED: 'Projects Page Viewed',
  PROPOSAL_ACCEPTED_CUSTOMER: 'Proposal Accepted Customer',
  PROPOSAL_ACTION_REQUIRED_CLICKED: 'Proposal Action Required Clicked',
  PROPOSAL_ACTIVE_ROW_CLICKED: 'Proposal Active Row Clicked',
  PROPOSAL_ADD_VALUE_ENGINEERING_CLICKED:
    'Proposal Add Value Engineering Clicked',
  PROPOSAL_APPROVE_BUTTON_CLICKED: 'Proposal Approve Button Clicked',
  PROPOSAL_BACK_TO_COMPARISONS_BUTTON_CLICKED:
    'Proposal Back To Comparisons Button Clicked',
  PROPOSAL_BIDS_SWITCHED: 'Proposal Bids Switched',
  PROPOSAL_BIDS_VIEW_DETAILS_BUTTON_CLICKED:
    'Proposal Bids View Details Button Clicked',
  PROPOSAL_BIDS_VIEW_PROPOSALS_BUTTON_CLICKED:
    'Proposal Bids View Proposals Button Clicked',
  PROPOSAL_CHANGE_REQUESTED_BY_CUSTOMER:
    'Proposal Change Requested By Customer',
  PROPOSAL_CHANGE_REQUESTED_BY_PRO: 'Proposal Change Requested By Pro',
  PROPOSAL_COMPARISON_PAGE_VIEWED: 'Proposal Comparison Page Viewed',
  PROPOSAL_COMPLETED_PROJECTS_CLICKED: 'Proposal Completed Projects Clicked',
  PROPOSAL_COMPLETED_PROJECTS_IMAGE_SWITCHED:
    'Proposal Completed Project Image Switched',
  PROPOSAL_CONTRACT_REMOVED: 'Proposal Contract Removed',
  PROPOSAL_CONTRACT_UPLOADED: 'Proposal Contract Uploaded',
  PROPOSAL_CREATED: 'Proposal Created',
  PROPOSAL_CREATE_NEW_VIEWED: 'Create Proposal Page Viewed',
  PROPOSAL_CUSTOMER_FEEDBACK_MODAL_CLOSED:
    'Proposal Customer Feedback Modal Closed',
  PROPOSAL_CUSTOMER_FEEDBACK_SELECTED: 'Proposal Customer Feedback Selected',
  PROPOSAL_CUSTOMER_FEEDBACK_SENT: 'Proposal Customer Feedback Sent',
  PROPOSAL_DRAFT_SAVED: 'Proposal Draft Saved',
  PROPOSAL_PREPARE_CONSTRUCTION_AGREEMENT_BUTTON_CLICKED:
    'Proposal Prepare Construction Agreement Button Clicked',
  PROPOSAL_PREVIEW_BUTTON_CLICKED: 'Proposal Preview Button Tapped',
  PROPOSAL_REFERRAL_ACCEPTED: 'Proposal Referral Accepted',
  PROPOSAL_REFERRAL_DECLINED: 'Proposal Referral Declined',
  PROPOSAL_SAVE_DRAFT_CLICKED: 'Proposal Save Draft Clicked',
  PROPOSAL_SEND_CONFIRMATION_MODAL_BUTTON_CLICKED:
    'Send Proposal Confirmation Modal Button Clicked',
  PROPOSAL_SEND_PROPOSAL_TO_HO_CLICKED: 'Proposal Send Proposal To HO Clicked',
  PROPOSAL_SENT_TO_HO: 'Proposal Sent To HO',
  PROPOSAL_SUMMARY_ITEM_ADD_CLICKED: 'Proposal Summary Item Add Clicked',
  PROPOSAL_SUMMARY_ITEM_EDIT_CANCEL_CLICKED:
    'Proposal Summary Item Edit Cancel Clicked',
  PROPOSAL_SUMMARY_ITEM_EDIT_CLICKED: 'Proposal Summary Item Edit Clicked',
  PROPOSAL_SUMMARY_ITEM_EDIT_SAVE_CLICKED:
    'Proposal Summary Item Edit Save Clicked',
  PROPOSAL_UPDATE_AGREEMENT: 'Proposal Update Agreement',
  PROPOSAL_VIEWED: 'Proposal Viewed',
  PROPOSAL_VOIDED: 'Proposal Voided',
  PURCHASE_ORDER_CREATE_INVOICE_CLICKED:
    'Purchase Order Create Invoice Clicked',
  PURCHASE_ORDER_FINALIZE_INVOICE_CLICKED:
    'Purchase Order Finalize Invoice Clicked',
  PURCHASE_ORDER_VIEWED: 'Purchase Order Viewed',
  PURCHASE_ORDER_ACTION_REQUIRED_CLICKED:
    'Purchase Order Action Required Clicked',
  QUOTES_AND_ORDERS_TAB_CLICKED: 'Quotes And Orders Tab Clicked',
  REVIEW_COTTAGE_LINK_CLICKED: 'Review Cottage Link Clicked',
  SIGN_IN_FAILED: 'Sign In Failed',
  SIGN_IN_SUCCESSFUL: 'Signed In',
  SIGNED_OUT: 'Signed Out',
  SITE_VISIT_DATE_SAVED: 'Site Visit Date Saved',
  STRIPE_PAPER_CHECK_RECEIVED: 'Stripe Paper Check Received',
  SUPPORT_BUTTON_CLICKED: 'Support Button Clicked',
  SUPPORT_MODAL_CLOSED: 'Support Modal Closed',
  SUPPORT_MODAL_FAILED: 'Support Modal Failed',
  SUPPORT_MODAL_SUBMIT: 'Support Modal Submit',
  TAB_SWITCHED: 'Tab Switched',
  TABLE_FILTER_APPLIED: 'Table Filter Applied',
  TABLE_ROW_CLICKED: 'Table Row Clicked',
  TEAM_ROSTER_REQUEST_PRO_BUTTON_CLICKED:
    'Team Roster Request Pro Button Clicked',
  UPDATE_SITE_WORK_BUTTON_CLICKED: 'Update Site Work Button Clicked',
  UPSELL_BANNER_CLICKED: 'Upsell Banner Clicked',
  UPSELL_REFERRAL_GENERATED: 'Upsell Referral Generated',
  USER_SIGNUP_SIGNUP_BUTTON_CLICKED: 'User Signup Signup Button Clicked',
  USER_SIGNIN_BUTTON_CLICKED: 'User Signin Button Clicked',
  WORK_ORDER_ACCEPTED: 'Work Order Accepted',
  WORK_ORDER_CREATED: 'Work Order Created',
  WORK_ORDER_DECLINED: 'Work Order Declined',
  WORK_ORDER_DOWNLOAD_WORK_ORDER_BUTTON_CLICKED:
    ' Work Order Download Work Order Button Clicked',
  WORK_ORDERS_PAGE_VIEWED: 'Work Orders Page Viewed',
  WORK_ORDERS_SCOPE_UPDATED: 'Work Orders Scope Updated',
  WORK_ORDER_VIEWED: 'Work Order Viewed',
  WORK_ORDER_VOIDED: 'Work Order Voided',
  WORK_ORDER_ROW_CLICKED: 'Work Order Row Clicked',
} as const;

export enum PAGES {
  AGREEMENT = 'Agreement Page',
  APPROVALS = 'Approvals Page',
  CHANGE_ORDER = 'Change Order Page',
  CONSTRUCTION_CLOSEOUT = 'Construction Closeout Page',
  HOME = 'Home Page',
  INVOICE = 'Invoice Page',
  INVOICES = 'Invoices Page',
  INVOICE_CREATION = 'Invoice Creation Page',
  PROCUREMENTS = 'Procurement Page',
  PROJECT = 'Project Page',
  PROJECTS = 'Projects Page',
  PROJECT_APPROVALS = 'Project Approvals Page',
  PROJECT_INVOICES = 'Project Invoices Page',
  PROJECT_WORK_ORDERS = 'Project Work Orders Page',
  PROPOSAL = 'Proposal Page',
  PURCHASE_ORDER = 'Purchase Order Page',
  QUOTES_AND_ORDERS = 'Quotes And Orders Page',
  SUPPORT_MODAL = 'Support Modal',
  WORK_ORDER = 'Work Order Page',
  WORK_ORDERS = 'Work Orders Page',
}

export const COMPONENTS = {
  PROJECT_CARD: 'Project Card',
  PROJECT_HEADER: 'Project Header',
  USER_NOTE: 'User Note',
};
