import { Modal, ModalProps } from '@mantine/core';

import { zIndex } from '@/lib/styleUtils';

export type CottageModalProps = ModalProps;

/**
 * Note: Use BaseModal for modals that follow the common Cottage modal format.
 * Use CottageModal for custom modal use cases.
 * @returns a thin wrapper around Mantine Modal that uses some common styling.
 */
export const CottageModal = ({ ...props }: CottageModalProps) => {
  return (
    <Modal
      zIndex={zIndex.MODAL}
      yOffset="8vh"
      centered
      closeOnClickOutside
      overlayProps={{
        color: 'black',
        opacity: 0.5,
      }}
      withCloseButton={false}
      {...props}
    />
  );
};
