import { Avatar, createStyles, Flex, Group, Paper, Text } from '@mantine/core';
import { useState } from 'react';
import { TbChevronRight, TbFileText, TbNotes } from 'react-icons/tb';

import { openDocument } from '@/lib/documentUtils';

import ErrorModal from '../ErrorModal';
import { CottageLink } from './default';

const useStyles = createStyles(
  (theme, { dark, clickable }: { clickable: boolean; dark: boolean }) => ({
    docsLink: {
      ...(clickable && {
        cursor: 'pointer',
        '&:hover': {
          background: dark ? theme.colors.blue[1] : theme.colors.neutral[0],
        },
      }),
      ...(dark && { background: theme.colors.blue[0] }),
    },
  })
);

interface Base {
  title: string;
  dark?: boolean;
}

interface DocLinkWithLink extends Base {
  href: string;
  actionButton?: never;
  documentUuid?: never;
  documentVersion?: never;
  onClick?: () => void;
}

interface DocLinkWithUuid extends Base {
  documentUuid: string;
  actionButton?: never;
  documentVersion?: number;
  href?: never;
  onClick?: () => void;
}

interface DocLinkWithActionButton extends Base {
  actionButton: React.JSX.Element;
  documentUuid?: never;
  documentVersion?: never;
  href?: never;
  onClick?: never;
}

type DocLinkProps = DocLinkWithLink | DocLinkWithUuid | DocLinkWithActionButton;

export default function DocLink({
  title,
  href,
  actionButton,
  dark = false,
  documentUuid,
  documentVersion,
  onClick,
}: DocLinkProps) {
  const [errorApi, setErrorApi] = useState<Error>();
  const { classes } = useStyles({ dark, clickable: !actionButton });

  if (href) {
    return (
      <CottageLink
        href={href}
        icon={<TbNotes size="1.5rem" />}
        name={title}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      {errorApi && (
        <ErrorModal error={errorApi} onClose={() => setErrorApi(undefined)} />
      )}
      <Paper
        p="xs"
        className={classes.docsLink}
        onClick={
          documentUuid
            ? () => {
                void openDocument(documentUuid, setErrorApi, documentVersion);
                onClick?.();
              }
            : undefined
        }
        withBorder
      >
        <Flex align="center" justify="space-between">
          <Group align="center">
            <Group noWrap>
              <Avatar
                size={48}
                color={dark ? 'blue.7' : 'primaryColor'}
                variant={dark ? 'filled' : 'light'}
              >
                <TbFileText size="1.5rem" />
              </Avatar>
              <Text
                fw="bold"
                lineClamp={2}
                miw={0}
                sx={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {title}
              </Text>
            </Group>
          </Group>
          {actionButton ? (
            actionButton
          ) : (
            <TbChevronRight size="1rem" style={{ flexShrink: 0 }} />
          )}
        </Flex>
      </Paper>
    </>
  );
}
