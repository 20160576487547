import { Avatar, Card, Flex, Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import { RiCloseLine } from 'react-icons/ri';
import { TbFileText, TbJpg, TbNote, TbPdf, TbPng, TbZip } from 'react-icons/tb';

import { FileExtension, getFileExtension } from '@/lib/documentUtils';

type DocumentUploadTileProps = {
  fileName: string;
  fileType: string;
  onDelete: (fileName: string) => void;
};

function DocumentUploadTile({
  fileName,
  fileType,
  onDelete: handleDelete,
}: DocumentUploadTileProps) {
  let previewIcon;
  switch (getFileExtension(null, fileType)) {
    case FileExtension.DOC:
    case FileExtension.DOCX:
      previewIcon = <TbFileText />;
      break;
    case FileExtension.PDF:
      previewIcon = <TbPdf />;
      break;
    case FileExtension.ZIP:
      previewIcon = <TbZip />;
      break;
    case FileExtension.PNG:
      previewIcon = <TbPng />;
      break;
    case FileExtension.JPEG:
      previewIcon = <TbJpg />;
      break;
    case FileExtension.NONE:
    default:
      previewIcon = <TbNote />;
  }

  return (
    <Card withBorder pt={0} pl={0} pr={0}>
      <Flex direction="row-reverse">
        <RiCloseLine
          size={24}
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleDelete(fileName)}
        />
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap="md"
        h="100%"
      >
        <Avatar size={48}>
          <IconContext.Provider value={{ size: '32px' }}>
            {previewIcon}
          </IconContext.Provider>
        </Avatar>
        <Text
          size="xs"
          style={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {fileName}
        </Text>
      </Flex>
    </Card>
  );
}

export default DocumentUploadTile;
