import { SimpleGrid } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';

import DocumentUploadTile from '@/components/invoices/components/DocumentUploadTile';
import { Document } from '@/types/api/documents';

type OnDeleteFile = (fileName: string) => void;

type FilePreviewTilesProps = {
  existingFiles: Document[];
  filesToUpload: FileWithPath[];
  onDeleteExisting: OnDeleteFile;
  onDeleteNewFile: OnDeleteFile;
};

export const FilePreviewTiles = ({
  existingFiles,
  filesToUpload,
  onDeleteExisting,
  onDeleteNewFile,
}: FilePreviewTilesProps) => {
  const fileUploadPreviewTiles = [];
  fileUploadPreviewTiles.push(
    ...existingFiles.map((file) => (
      <DocumentUploadTile
        key={file.name}
        fileName={file.name}
        fileType={file.mime_type ?? ''}
        onDelete={onDeleteExisting}
      />
    ))
  );
  fileUploadPreviewTiles.push(
    ...filesToUpload.map((file) => (
      <DocumentUploadTile
        key={file.name}
        fileName={file.name}
        fileType={file.type}
        onDelete={onDeleteNewFile}
      />
    ))
  );

  if (fileUploadPreviewTiles.length === 0) {
    return <></>;
  }

  return (
    <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      {fileUploadPreviewTiles}
    </SimpleGrid>
  );
};
