import * as amplitude from '@amplitude/analytics-browser';

import { ANALYTICS_EVENTS, PAGES } from '@/lib/constants/analyticsEvents';
import { Document } from '@/types/api/documents';

import DocLink from '../DocLink';

type DocumentLinksProps = {
  documentType: string;
  documents: Document[];
  page: PAGES;
};

export const DocumentLinks = ({
  documentType,
  documents,
  page,
}: DocumentLinksProps) => {
  const docLinks = documents.map((document) => {
    return (
      <DocLink
        key={document.uuid}
        title={document.name}
        documentUuid={document.uuid}
        dark
        onClick={() => {
          amplitude.track({
            event_type: ANALYTICS_EVENTS.ATTACHMENT_TAPPED,
            event_properties: {
              currentPage: page,
              documentName: document.name,
              documentType,
              documentUuid: document.uuid,
            },
          });
        }}
      />
    );
  });

  return <>{docLinks}</>;
};
