import { createContext } from 'react';

export enum DisplaySize {
  COMPACT,
  DEFAULT,
}

type SizeContextType = {
  size: DisplaySize;
};

export const SizeContext = createContext<SizeContextType>({
  size: DisplaySize.DEFAULT,
});
