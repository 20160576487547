import { createContext } from 'react';

export enum LayoutType {
  DESKTOP,
  SMALL_DESKTOP,
  TABLET,
  MOBILE,
}

export type LayoutContextType = {
  layout: LayoutType;
};

export const LayoutContext = createContext<LayoutContextType>({
  layout: LayoutType.DESKTOP,
});
