import { FileWithPath } from '@mantine/dropzone';
import { DocumentCategory } from '@prisma/client';

import {
  Document,
  DocumentUploadUrlInfo,
  DownloadDocuments,
  FileInfo,
  PgDocumentResponse,
} from '@/types/api/documents';

import {
  downloadUrls,
  uploadUrls,
  viewUrl as getViewUrl,
} from './api/client/documents';
import { DOCUMENT_CATEGORY_TEXT } from './constants/documentCategories';
import { Role } from './constants/role';
import { openExternalUrl } from './linkUtils';
import { validateIsProfessional, validateRoles } from './roleUtils';
import { getFullName } from './stringUtils';

export enum FileExtension {
  DOC,
  DOCX,
  PDF,
  ZIP,
  PNG,
  JPEG,
  NONE,
}

export const MIMETYPE_TO_EXTENSION: { [index: string]: FileExtension } = {
  'application/msword': FileExtension.DOC,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FileExtension.DOCX,
  'application/pdf': FileExtension.PDF,
  'application/zip': FileExtension.ZIP,
  'image/png': FileExtension.PNG,
  'image/jpeg': FileExtension.JPEG,
};

/**
 * Maps a MIME type to a file extension acceptable by Docusign (e.g. .pdf, .docx)
 */
export const getFileExtension = (
  fileName: string | null,
  mimetype: string | null
): FileExtension => {
  const defaultExtension = fileName?.substring(
    fileName.lastIndexOf('.'),
    fileName?.length
  );

  // Some browsers might not be able to detect the MIME type from the file and
  // therefore won't provide one. If this happens, we'll determine the file name
  // using the file extension.
  let fileExtension = FileExtension.NONE;
  if (mimetype) {
    // continue to use defaultExtension if mimetype doesn't exist in
    // mimetypeToExtension
    fileExtension = MIMETYPE_TO_EXTENSION[mimetype] ?? defaultExtension;
  }

  return fileExtension;
};

export type DocumentUploadResponse = {
  documents: Document[];
  unsuccessfulUploadFileNames: string[];
  presignedUrl?: DocumentUploadUrlInfo;
};

/**
 * Creates a list of presigned URLs and uses them to upload to DigitalOcean.
 * @param {FileWithPath} files - Array of files to upload.
 * @param {String} bucketName - Optional. The bucket name to upload to. If not
 * specified, the default is 'cottage-files'.
 * @param {String} projectId - Optional. The project ID associated.
 * @param {String} originalUuid - Optional. If creating a new
 * document version, this represents the original document's UUID to be used
 * as the part of the DigitalOcean key.
 * @returns {Promise<DocumentUploadResponse>}
 */
export const uploadToDigitalOcean = async (
  files: FileWithPath[],
  bucketName?: string,
  projectId?: string,
  originalUuid?: string
): Promise<DocumentUploadResponse> => {
  if (files.length === 0) {
    return {
      documents: [],
      unsuccessfulUploadFileNames: [],
    };
  }
  const fileInfos: FileInfo[] = files.map((f) => {
    return {
      type: f.type,
      name: f.name,
    };
  });

  // Generate UUIDs for files along with pre-signed URLs which will be used to
  // securely upload files to Digital Ocean
  const filesWithUploadInfo = await uploadUrls({
    files: fileInfos,
    bucketName,
    originalUuid,
  });

  const documents: Document[] = [];
  const unsuccessfulUploadFileNames: string[] = [];
  let generatedFileInfo;

  for (const file of files) {
    generatedFileInfo = filesWithUploadInfo[file.name];
    if (!generatedFileInfo) {
      unsuccessfulUploadFileNames.push(file.name);
      continue;
    }

    const uploadResult = await fetch(generatedFileInfo.url, {
      method: 'PUT',
      headers: {
        'Content-type': file.type,
        'x-amz-acl': 'private',
      },
      body: file,
    });

    if (uploadResult.ok) {
      documents.push({
        name: file.name,
        mime_type: file.type,
        project_id: projectId ? parseInt(projectId) : undefined,
        uuid: generatedFileInfo.uuid,
        // At this point there is no document uploaded to the DB so there is
        // no corresponding version
        version: 0,
      });
    } else {
      unsuccessfulUploadFileNames.push(file.name);
    }
  }

  return {
    documents,
    unsuccessfulUploadFileNames,
    presignedUrl: generatedFileInfo,
  };
};

/**
 * Opens a document in a new tab.
 * @param {String} documentUuid - uuid of document to be viewed
 * @param {Function} handleError - function to set error state
 * @param {Number} documentVersion - The version of the document to obtain. If not
 * specified, returns the current active document.
 * @returns {Promise<void>}
 */
export const openDocument = async (
  documentUuid: string,
  handleError: (error: Error) => void,
  documentVersion?: number,
  triggerDownload?: boolean
): Promise<void> => {
  try {
    const viewInfo = await getViewUrl(
      documentUuid,
      documentVersion,
      triggerDownload
    );
    openExternalUrl(viewInfo.url);
  } catch (error) {
    handleError(new Error(`Unable to open document: ${error}`));
  }
};

/**
 * Generates a presigned URL for downloading a file from DigitalOcean.
 * @param {Document[]} documents The documents to download.
 * @param {String} bucketName - Optional. The name of the bucket. If not
 * specified, the default is 'cottage-files'.
 * @param {Number} expiresIn - Optional. The expiration time in seconds.
 * @returns A promise that resolves to the list of presigned URLs with their
 * file names.
 */
export const generatePresignedDownloadUrls = async (
  documents: Document[],
  bucketName?: string,
  expiresIn: number = 60 * 5
): Promise<DownloadDocuments[]> => {
  const urls = await downloadUrls({ documents, bucketName, expiresIn });
  return urls;
};

export const getUploaderName = (
  document: PgDocumentResponse
): string | null => {
  return (
    document.customer?.full_name ??
    document.internal?.full_name ??
    getFullName(document.user?.first_name, document.user?.last_name)
  );
};

export const getUploaderRole = (document: PgDocumentResponse): string[] => {
  if (document.customer_id) {
    return [Role.CUSTOMER];
  } else if (document.internal_id) {
    return [Role.INTERNAL];
  } else if (document.user) {
    return document.user.roles.map((role) => role.name);
  }
  return [];
};

export const isAccessControlledCategory = (
  category: DocumentCategory | null
) => {
  return (
    category === DocumentCategory.PROPOSAL_AND_CONTRACTS ||
    category === DocumentCategory.PROCUREMENT
  );
};

export const isNotVersionableCategory = (category: DocumentCategory | null) => {
  return (
    category === DocumentCategory.CONSULTANT_DELIVERABLE ||
    category === DocumentCategory.PROPOSAL_AND_CONTRACTS ||
    category === DocumentCategory.PROCUREMENT
  );
};

/**
 * Culls documents by search term, category, and or uploader name.
 * @returns {PgDocumentResponse[]} list of proposals that match the searchTerm
 */
export const filterDocuments = (
  documents: PgDocumentResponse[],
  searchTerm?: string,
  category?: string,
  uploaderName?: string
): PgDocumentResponse[] => {
  if (!searchTerm && !category && !uploaderName) {
    return documents;
  }

  return documents.filter((document) => {
    const lowerSearchTerm = searchTerm?.toLowerCase().trim();
    const lowerCategory = category?.toLowerCase().trim();
    const lowerUploaderName = uploaderName?.toLowerCase().trim();

    const matchesSearchTerm =
      !lowerSearchTerm ||
      document.name.toLowerCase().includes(lowerSearchTerm) ||
      getUploaderName(document)?.toLowerCase().includes(lowerSearchTerm) ||
      (document.category &&
        DOCUMENT_CATEGORY_TEXT[document.category]
          .toLowerCase()
          .includes(lowerSearchTerm));

    const matchesCategory =
      !lowerCategory ||
      (document.category &&
        DOCUMENT_CATEGORY_TEXT[document.category]
          .toLowerCase()
          .includes(lowerCategory));

    const matchesUploaderName =
      !lowerUploaderName ||
      getUploaderName(document)?.toLowerCase() === lowerUploaderName;

    return matchesSearchTerm && matchesCategory && matchesUploaderName;
  });
};

export const TABLE_CATEGORIES = [
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.CONCEPTUAL_DESIGN],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.SCHEMATIC_DESIGN],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.DESIGN_DEVELOPMENT],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.SPECS_AND_FINISHES],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.PERMITTING],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.CONSTRUCTION],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.CONSULTANT_DELIVERABLE],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.PROCUREMENT],
  DOCUMENT_CATEGORY_TEXT[DocumentCategory.PROPOSAL_AND_CONTRACTS],
] as const;

export const getAmplitudeEventProperties = (document: PgDocumentResponse) => ({
  projectId: document.project_id,
  documentUuid: document.uuid,
  version: document.version,
});

export const isDocumentUploader = (
  document: PgDocumentResponse,
  sessionUserId?: string,
  roles?: string[]
) => {
  if (!sessionUserId || !roles) {
    return false;
  }

  const userId = parseInt(sessionUserId);

  const { isCustomer, isInternal } = validateRoles(roles ?? []);
  const isProfessional = validateIsProfessional(roles);

  if (isCustomer && document.customer_id) {
    return document.customer_id === userId;
  } else if (isInternal && document.internal_id) {
    return document.internal_id === userId;
  } else if (isProfessional && document.user_id) {
    return document.user_id === userId;
  }
  return false;
};

/**
 * A user is a document owner if they are a pro and in the same company as the
 *  document uploader. Otherwise, they are the owner if they are the uploader.
 * @param document
 * @param sessionUserId
 * @param roles
 * @returns Whether the user owns the document.
 */
export const isDocumentOwner = (
  document: PgDocumentResponse,
  sessionUserId?: string,
  roles?: string[]
) => {
  if (!sessionUserId || !roles) {
    return false;
  }

  const userId = parseInt(sessionUserId);

  const isProfessional = validateIsProfessional(roles);

  if (isProfessional && document.user_id && document.user?.companies) {
    return document.user?.companies.some((company) =>
      company.users.some((user) => user.id === userId)
    );
  }

  return isDocumentUploader(document, sessionUserId, roles);
};

/**
 * The file result
 * @typedef {Object} SplitFilenameResult
 * @property {number} name - The file name
 * @property {number} extension - The suffix file name
 */
/**
 * Returns both the prefix and the extension. Always returns a prefix.
 * EG1: "test2" would have a <prefix> group of "test2."
 * EG2: "test2.pdf" would have a <prefix> group of "test2" and an <extension> group of ".pdf"
 * EG3: "test2.pdf.pdf" would have a <prefix> group of "test2.pdf" and an <extension> group of ".pdf"
 * @param {string} pgFileName The filename to split.
 * @returns {SplitFilenameResult} The resulting file info.
 */
export const extractFileInfoFromFileName = (pgFileName: string) => {
  const regex = /(.*?)(\.[^.]*?)?$/m;
  const matchResults = regex.exec(pgFileName);

  if (!matchResults) {
    return {
      name: pgFileName,
      extension: undefined,
    };
  }

  return {
    name: matchResults[1],
    extension: matchResults[2],
  };
};
