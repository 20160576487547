import { Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode } from 'react';

import { CottageModal } from './shared/default';

type ErrorModalProps = {
  customError?: ReactNode;
  error?: { message: string };
  onClose?: () => void;
  title?: string;
};

function ErrorModal({ error, customError, onClose, title }: ErrorModalProps) {
  const [opened, { close }] = useDisclosure(true, {
    onClose,
  });

  return (
    <CottageModal
      opened={opened}
      closeOnClickOutside={false}
      onClose={close}
      title={title ? title : 'An error occurred'}
      size={500}
      withCloseButton
    >
      {customError ? customError : <Text>{error?.message}</Text>}
    </CottageModal>
  );
}

export default ErrorModal;
