import { Input, InputProps } from '@mantine/core';
import { forwardRef, useContext } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

export type CottageInputProps = InputProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
  };

/**
 * @returns Mantine Input that defaults to size medium on small screen
 * sizes.
 */
export const CottageInput = forwardRef<HTMLInputElement, CottageInputProps>(
  ({ ...props }: CottageInputProps, ref) => {
    const { layout } = useContext(LayoutContext);

    return (
      <Input
        ref={ref}
        size={layout === LayoutType.MOBILE ? 'md' : undefined}
        {...props}
      />
    );
  }
);
CottageInput.displayName = 'CottageInput';
