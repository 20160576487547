import { Select, SelectProps } from '@mantine/core';
import { forwardRef, useContext } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

export type CottageSelectProps = SelectProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size' | 'onChange' | 'value'>;

/**
 * @returns Mantine Select that defaults to size medium on small screen
 * sizes.
 */
export const CottageSelect = forwardRef<HTMLInputElement, CottageSelectProps>(
  ({ ...props }: CottageSelectProps, ref) => {
    const { layout } = useContext(LayoutContext);
    const isMobile = layout === LayoutType.MOBILE;

    return (
      <Select
        ref={ref}
        size={isMobile ? 'md' : undefined}
        dropdownPosition="bottom"
        maxDropdownHeight={isMobile ? 120 : undefined}
        {...props}
      />
    );
  }
);
CottageSelect.displayName = 'CottageSelect';
