import { Box, createStyles, Paper, Stack } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useContext, useEffect } from 'react';

import { zIndex as zIndexEnum } from '@/lib/styleUtils';

import { PageOffsetContext } from './contexts/PageOffsetContext';

export enum ActionBarPosition {
  TOP,
  BOTTOM,
}

const useStyles = createStyles(
  (
    _theme,
    {
      position,
      withShadow,
      zIndex,
    }: { position: ActionBarPosition; withShadow: boolean; zIndex: number }
  ) => ({
    container: {
      position: 'fixed',
      left: 0,
      width: '100%',
      zIndex: zIndex,
      ...(withShadow && {
        boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 
          0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
          0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
      }),
      ...(position === ActionBarPosition.BOTTOM && {
        bottom: 0,
      }),
      ...(position === ActionBarPosition.TOP && {
        top: 0,
      }),
    },
  })
);
type ActionBarProps = {
  children: React.ReactNode;
  padding?: string;
  position?: ActionBarPosition;
  updateOffset?: boolean;
  withShadow?: boolean;
  zIndex?: number;
};

export default function ActionBar({
  children,
  padding = 'xl',
  position = ActionBarPosition.BOTTOM,
  updateOffset = true,
  withShadow = true,
  zIndex = zIndexEnum.ACTION_BAR,
}: ActionBarProps) {
  const { updateOffsetTop, updateOffsetBottom } = useContext(PageOffsetContext);
  const { ref, height } = useElementSize();

  const { classes } = useStyles({ position, withShadow, zIndex });

  useEffect(() => {
    if (!updateOffset) return;

    // Clean up function to reset page offset after ActionBar unmounts
    return () => {
      position === ActionBarPosition.BOTTOM
        ? updateOffsetBottom(0)
        : updateOffsetTop(0);
    };
  }, []);

  useEffect(() => {
    if (!updateOffset) return;

    position === ActionBarPosition.BOTTOM
      ? updateOffsetBottom(height)
      : updateOffsetTop(height);
  }, [height, position, updateOffset, updateOffsetTop, updateOffsetBottom]);

  return (
    <Box className={classes.container} ref={ref}>
      <Paper p={padding}>
        <Stack spacing="sm">{children}</Stack>
      </Paper>
    </Box>
  );
}
