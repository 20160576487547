import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

const emptyRoles: string[] = [];

/**
 * Returns a string array containing the user's roles. The reference of this string
 * array remains the same as long as its contents remain the same. This behavior
 * is different from getting the value of `session.user.roles` from `useSession()`,
 * whose reference changes every time the latest session data is fetched, which
 * may cause unnecessary effects to be run if `session.user.roles` is passed as a
 * dependency to `useEffect`.
 *
 * You can replace all usages of `session.user.roles` with `useUserRoles()` but
 * you only really need this hook if you need a consistent reference to the user
 * roles array that only changes with its contents, such as in the case of a hook
 * for data loading. e.g.
 *
 * export const useGetSomeData = () => {
 *    const roles = useUserRoles();
 *    const [someData, setSomeData] = useState();
 *
 *    useEffect(() => {
 *      const loadedData = // load data that depends on roles
 *      setSomeData(loadedData)
 *    }, [roles])
 *
 *    return { someData };
 * };
 */
export const useUserRoles = (): string[] => {
  const { data: session } = useSession({ required: true });
  const rolesStr = JSON.stringify(session?.user?.roles);

  return useMemo(
    () => (rolesStr ? JSON.parse(rolesStr) : emptyRoles),
    [rolesStr]
  );
};
