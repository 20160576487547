import { Badge, createStyles, Flex } from '@mantine/core';
import Link from 'next/link';

type NavigationMenuItemProps = {
  isSelected: boolean;
  path: string;
  title: string;
  toggleIsMobileNavOpen: (isOpen: boolean) => void;
  badge?: string;
  gap?: string;
};

export default function NavigationMenuItem({
  badge,
  isSelected,
  path,
  title,
  gap = '0px',
  toggleIsMobileNavOpen,
}: NavigationMenuItemProps) {
  const { classes, cx } = createStyles((theme, gap: string) => ({
    fullHeight: {
      height: '100%',
    },
    navItem: {
      marginLeft: gap,
      padding: theme.spacing.sm,
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        height: 'auto',
        padding: theme.spacing.md,
        width: '100%',
        marginLeft: 0,
        cursor: 'pointer',
        '&:hover': {
          ...(!isSelected && { background: theme.colors.blue[0] }),
        },
      },
    },
    selected: {
      fontWeight: 'bold',
      boxShadow: 'inset 0px -4px 0px #FFFFFF',
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        fontWeight: 'inherit',
        boxShadow: 'none',
        background: theme.colors.blue[1],
        borderRadius: '4px',
      },
    },
  }))(gap);

  return (
    <Link
      href={path}
      onClick={() => toggleIsMobileNavOpen(false)}
      className={classes.fullHeight}
    >
      <Flex
        align="center"
        className={cx(classes.navItem, classes.fullHeight, {
          [classes.selected]: isSelected,
        })}
      >
        {title}
        {badge && (
          <Badge color="green" radius="sm" size="sm" ml="xs">
            {badge}
          </Badge>
        )}
      </Flex>
    </Link>
  );
}
