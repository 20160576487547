import { createStyles } from '@mantine/core';
import { useSession } from 'next-auth/react';
import { ReactNode, useContext } from 'react';

import { PageOffsetContext } from '@/components/shared/contexts/PageOffsetContext';

import Navigation from '../navigation/Navigation';
import Meta from './meta';

const useStyles = createStyles(
  (
    theme,
    { offsetBottom, offsetTop }: { offsetBottom: number; offsetTop: number }
  ) => ({
    container: {
      background: theme.colors.blue[0],
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      background: theme.colors.blue[0],
      paddingBottom: offsetBottom,
      paddingTop: offsetTop,
    },
  })
);

export default function Layout({
  meta,
  children,
  includeNav = true,
}: {
  children: ReactNode;
  includeNav?: boolean;
  meta?: {
    description?: string;
    image?: string;
    title?: string;
  };
}) {
  const { offsetBottom, offsetTop } = useContext(PageOffsetContext);

  const { classes } = useStyles({ offsetBottom, offsetTop });
  const { data: session } = useSession();

  return (
    <div className={classes.container}>
      <Meta {...meta} />
      {includeNav && <Navigation session={session} />}
      <main className={classes.main}>{children}</main>
    </div>
  );
}
