import { useContext } from 'react';
import { IconContext } from 'react-icons';

import { DisplaySize, SizeContext } from '../contexts/SizeContext';
import { CottageButton, CottageButtonProps } from '../default';

export type ActionButtonProps = Omit<CottageButtonProps, 'fullWidth'>;

export default function ActionButton({
  leftIcon,
  ...props
}: ActionButtonProps) {
  const { size } = useContext(SizeContext);

  return (
    <CottageButton
      fullWidth={size !== undefined && size !== DisplaySize.COMPACT}
      leftIcon={
        leftIcon ? (
          <IconContext.Provider value={{ size: '18px' }}>
            {leftIcon}
          </IconContext.Provider>
        ) : undefined
      }
      {...props}
    />
  );
}
