import { SimpleGrid } from '@mantine/core';

type CottageGridProps = {
  children: React.JSX.Element[];
  defaultCols: number;
};

/**
 *
 * @param {number} defaultCols - number of columns on desktop
 * @returns Mantine SimpleGrid the collapses into single column on small
 * screen sizes
 */
export const CottageGrid = ({ children, defaultCols }: CottageGridProps) => {
  return (
    <SimpleGrid cols={defaultCols} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      {children}
    </SimpleGrid>
  );
};
