import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { projects as getProjects } from '@/lib/api/client/projects';
import { Project } from '@/types/api';

export type ProjectFilter = (project: Project) => boolean;

function useProjects(
  filterFunc?: ProjectFilter,
  excludeAirTableData: boolean = false
) {
  const [projects, setProjects] = useState<Project[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const { status } = useSession({ required: true });

  useEffect(() => {
    setLoading(true);
    getProjects(excludeAirTableData)
      .then((response) => {
        const filteredProjects = filterFunc
          ? response.filter(filterFunc)
          : response;
        setProjects(filteredProjects);
      })
      .catch((e: Error) => setError(e))
      .finally(() => setLoading(false));
  }, [status]);

  // Don't include filterFunc in the dependency array as it will be re-created
  // every time the component re-renders and getProjects will be called every
  // time

  return {
    projects,
    loading,
    error,
  };
}

export default useProjects;
