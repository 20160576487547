import axios, { AxiosResponse } from 'axios';

import { isDateTime } from '../../stringUtils';

export const api = axios.create({
  headers: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

const stringToDateMiddleware = (
  response: AxiosResponse<Record<string, unknown> | Record<string, unknown>[]>
) => {
  if (response.data && typeof response.data === 'object') {
    response.data = convertStringsToDate(response.data);
  }
  return response;
};

// Add response interceptor
api.interceptors.response.use(stringToDateMiddleware, (error) => {
  return Promise.reject(error);
});

/**
 * Recursively traverses the provided object and tries to convert any fields
 * that are strings in the format of a date to a Date. We do this because prisma
 * and our data model objects give the type Date to certain fields, but when
 * those fields are serialized to JSON they are converted to strings. This
 * converts them back to Dates.
 * @param data API response to traverse
 * @returns Provided API response with valid fields converted from strings to
 * Dates
 */
function convertStringsToDate(
  data: Record<string, unknown> | Record<string, unknown>[]
): Record<string, unknown> | Record<string, unknown>[] {
  if (Array.isArray(data)) {
    const arr = data.map((item) => convertStringsToDate(item));
    return arr as Record<string, unknown>[];
  }

  const result: Record<string, unknown> = {};

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];

      if (Array.isArray(value)) {
        result[key] = value.map((item) =>
          convertStringsToDate(item as Record<string, unknown>)
        );
      } else if (typeof value === 'string' && isDateTime(value)) {
        const parsedDate = new Date(value);
        if (!Number.isNaN(parsedDate.getTime())) {
          result[key] = parsedDate;
        } else {
          result[key] = value;
        }
      } else if (typeof value === 'object' && value !== null) {
        result[key] = convertStringsToDate(value as Record<string, unknown>);
      } else {
        result[key] = value;
      }
    }
  }

  return result;
}
