import { Divider, DividerProps } from '@mantine/core';

import { useContainerStyles } from '@/lib/styleUtils';

type CottageContainerDividerProps = DividerProps & {
  useNegativeMargins?: boolean;
};

export const CottageContainerDivider = ({
  useNegativeMargins,
  ...props
}: CottageContainerDividerProps) => {
  const { classes } = useContainerStyles();

  return (
    <Divider
      className={useNegativeMargins ? classes.negativeMarginX : ''}
      {...props}
    />
  );
};
