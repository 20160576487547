import { createContext, useState } from 'react';

type PageOffsetContextType = {
  offsetBottom: number;
  offsetTop: number;
  updateOffsetBottom: (offset: number) => void;
  updateOffsetTop: (offset: number) => void;
};

export const PageOffsetContext = createContext<PageOffsetContextType>({
  offsetTop: 0,
  offsetBottom: 0,
  updateOffsetTop: () => {},
  updateOffsetBottom: () => {},
});

type PageOffsetProviderType = {
  children: React.ReactNode;
};

export const PageOffsetProvider = ({ children }: PageOffsetProviderType) => {
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [offsetBottom, setOffsetBottom] = useState<number>(0);

  return (
    <PageOffsetContext.Provider
      value={{
        offsetTop,
        offsetBottom,
        updateOffsetTop: setOffsetTop,
        updateOffsetBottom: setOffsetBottom,
      }}
    >
      {children}
    </PageOffsetContext.Provider>
  );
};
