import { Stack } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useEffect, useRef, useState } from 'react';

import { PAGES } from '@/lib/constants/analyticsEvents';
import { Document } from '@/types/api/documents';

import { DocumentLinks } from './DocumentLinks';
import { Dropzone, DropzoneProps } from './Dropzone';
import { FilePreviewTiles } from './FilePreviewTiles';
import { SingleFileDocLink } from './SingleFileDocLink';

const removeFile = (name: string, files: FileWithPath[] | Document[]) =>
  files.filter((f) => f.name !== name);

export type DocumentUploadProps = DropzoneProps & {
  documentType: string;
  page: PAGES;
};

/**
 * Displays a Dropzone component as well as links to the uploaded and existing
 * files.
 * For single files, the dropzone component is hidden when a file has been
 * uploaded and only a DocLink is displayed.
 */
export default function DocumentUpload({
  filesToUpload,
  setFilesToUpload,
  existingFiles = [],
  setExistingFiles,
  documentType,
  isSingleFile = false,
  page,
  ...props
}: DocumentUploadProps) {
  const [hideDropzone, setHideDropzone] = useState(false);
  const isMultiFileUploadView = !isSingleFile;
  const openRef = useRef<() => void>(null);

  useEffect(() => {
    const hasFile = existingFiles.length > 0 || filesToUpload.length > 0;
    setHideDropzone(isSingleFile && hasFile);
  }, [existingFiles, filesToUpload, isSingleFile, setHideDropzone]);

  const onDeleteExisting = (fileName: string) =>
    setExistingFiles?.(removeFile(fileName, existingFiles) as Document[]);

  const onDeleteNewFile = (fileName: string) =>
    setFilesToUpload(removeFile(fileName, filesToUpload) as FileWithPath[]);

  let fileUploadPreviewTiles: React.JSX.Element | null = null;
  if (isMultiFileUploadView) {
    fileUploadPreviewTiles = (
      <FilePreviewTiles
        existingFiles={existingFiles}
        filesToUpload={filesToUpload}
        onDeleteExisting={onDeleteExisting}
        onDeleteNewFile={onDeleteNewFile}
      />
    );
  }

  const fileLinks = isMultiFileUploadView ? (
    <DocumentLinks
      documentType={documentType}
      documents={existingFiles}
      page={page}
    />
  ) : (
    <SingleFileDocLink
      existingFiles={existingFiles}
      filesToUpload={filesToUpload}
      onDeleteExisting={onDeleteExisting}
      onDeleteNewFile={onDeleteNewFile}
      page={page}
    />
  );

  return (
    <Stack spacing="xs">
      {!hideDropzone && (
        <Dropzone
          filesToUpload={filesToUpload}
          setFilesToUpload={setFilesToUpload}
          existingFiles={existingFiles}
          setExistingFiles={setExistingFiles}
          isSingleFile={isSingleFile}
          ref={openRef}
          {...props}
        />
      )}
      {fileUploadPreviewTiles}
      {fileLinks}
    </Stack>
  );
}
