import { createStyles, Radio, RadioProps } from '@mantine/core';

type CottageRadioCardProps = RadioProps;

const useStyles = createStyles((theme) => ({
  radio: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.colors.blue[0],
    },
    '&[data-checked="true"]': {
      backgroundColor: theme.colors.blue[0],
      borderColor: theme.colors.gray[9],
    },
    '.mantine-Radio-label': {
      cursor: 'pointer',
      padding: '24px',
      paddingLeft: '12px',
    },
    '.mantine-Radio-inner': {
      alignSelf: 'center',
      WebkitAlignSelf: 'center',
      marginLeft: '12px',
    },
    '.mantine-Radio-radio': {
      cursor: 'pointer',
    },
  },
}));

export const CottageRadioCard = (props: CottageRadioCardProps) => {
  const { classes } = useStyles();

  return <Radio className={classes.radio} {...props} />;
};
