import { Input, InputWrapperProps } from '@mantine/core';
import { forwardRef, useContext } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

/**
 * @returns Mantine Input.Wrapper that defaults to size medium on small screen
 * sizes.
 */
export const CottageInputWrapper = forwardRef<
  HTMLDivElement,
  InputWrapperProps
>(({ ...props }: InputWrapperProps, ref) => {
  const { layout } = useContext(LayoutContext);

  return (
    <Input.Wrapper
      ref={ref}
      size={layout === LayoutType.MOBILE ? 'md' : undefined}
      {...props}
    />
  );
});
CottageInputWrapper.displayName = 'CottageInputWrapper';
