import { ActionIcon } from '@mantine/core';
import { TbX } from 'react-icons/tb';

type CottageCloseButtonProps = {
  onClose: () => void;
};
export const CottageCloseButton = ({ onClose }: CottageCloseButtonProps) => {
  return (
    <ActionIcon
      variant="transparent"
      onClick={onClose}
      style={{
        position: 'absolute',
        top: '16px',
        right: '16px',
      }}
      data-testid="close-button"
    >
      <TbX size={24} />
    </ActionIcon>
  );
};
