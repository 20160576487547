import { CompanyType } from '@prisma/client';

import { Company } from '@/types/api';

export const COMPANY_TYPES = Object.freeze({
  CONSULTANT: 'consultant',
  GC: 'gc',
});

export const EMPTY_COMPANY: Company = Object.freeze({
  id: 0,
  name: '',
  email: '',
  profile_picture: '',
  stripeAccountId: '',
  hasPayoutsEnabled: false,
  isNewProfessional: false,
  companyType: CompanyType.GC,
  users: [],
});
