import { Alert, AlertProps, createStyles, Variants } from '@mantine/core';
import { forwardRef } from 'react';
import { IconContext } from 'react-icons/lib';

export type CottageAlertProps = React.ComponentPropsWithoutRef<'div'> &
  Omit<AlertProps, 'children'> & { children?: React.ReactNode };

const ICON_SIZE = '24px';
const useStyles = createStyles(
  (
    theme,
    {
      variant,
      color = '',
      hasChildren,
    }: {
      hasChildren: boolean;
      color?: string;
      variant?: Variants<'filled' | 'outline' | 'light'>;
    }
  ) => {
    // For variants with mismatched background colors with their text color,
    // use our primary color for the icon & title text.
    const useDefaultTextColor = variant === 'light' && color === 'ivory';
    const defaultTextColor = theme.colors[theme.primaryColor][9];
    const [baseColor] = color.split('.');

    let borderColor;
    let closeButtonColor = theme.fn.variant({
      variant: 'outline',
      color,
    }).color;
    if (baseColor === 'ivory') {
      borderColor = theme.colors.ivory[1];
    } else if (baseColor === 'white') {
      borderColor = theme.colors.blue[3];
      closeButtonColor = theme.colors.blue[3];
    } else if (baseColor) {
      borderColor = theme.colors[baseColor][3];
    } else {
      borderColor = theme.colors[theme.primaryColor][3];
    }

    return {
      closeButton: {
        color: closeButtonColor,
        marginLeft: theme.spacing.xxxs,
      },
      icon: {
        // This sets the icon container dimensions.
        width: ICON_SIZE,
        height: ICON_SIZE,
        ...(useDefaultTextColor && { color: defaultTextColor }),
        [`@media (max-width: ${theme.breakpoints.sm})`]: {
          marginRight: theme.spacing.xs,
        },
      },
      message: {
        ...(variant !== 'filled' && { color: defaultTextColor }),
      },
      root: {
        ...(variant === 'light' && { borderColor: borderColor }),
      },
      title: {
        marginBottom: hasChildren ? theme.spacing.xxxs : '0',
        ...(useDefaultTextColor && { color: defaultTextColor }),
      },
    };
  }
);

/**
 * @returns Mantine Alert that adds an outline to the light variant.
 */
export const CottageAlert = forwardRef<HTMLDivElement, CottageAlertProps>(
  (
    { color, children, variant = 'light', icon, ...props }: CottageAlertProps,
    ref
  ) => {
    const { classes } = useStyles({ variant, color, hasChildren: !!children });
    return (
      <Alert
        ref={ref}
        classNames={{
          icon: classes.icon,
          message: classes.message,
          root: classes.root,
          title: classes.title,
          closeButton: classes.closeButton,
        }}
        variant={variant}
        color={color}
        icon={
          icon && (
            <IconContext.Provider value={{ size: ICON_SIZE }}>
              {icon}
            </IconContext.Provider>
          )
        }
        {...props}
      >
        {children}
      </Alert>
    );
  }
);
CottageAlert.displayName = 'CottageAlert';
