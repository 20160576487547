import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { supportCategories as getSupportCategories } from '@/lib/api/client/support';
import { SupportCategory } from '@/types/api';

function useSupportCategories() {
  const [supportCategories, setSupportCategories] =
    useState<SupportCategory[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const { status } = useSession({ required: true });

  useEffect(() => {
    setLoading(true);
    getSupportCategories()
      .then((response) => setSupportCategories(response))
      .catch((e: Error) => setError(e))
      .finally(() => setLoading(false));
  }, [status]);

  return {
    supportCategories,
    loading,
    error,
  };
}

export default useSupportCategories;
