import { AxiosError } from 'axios';

import { HttpError } from '@/types/api';
import {
  CreateDocumentRequest,
  DocumentUploadUrlInfo,
  DocumentViewUrlInfo,
  DownloadDocuments,
  DownloadUrlsRequest,
  PgDocument,
  PgDocumentResponse,
  StreamDoToGDriveRequest,
  StreamDoToGDriveResponse,
  UpdateDocumentRequest,
  UploadUrlsRequest,
} from '@/types/api/documents';

import { api } from './axios';

export const downloadUrls = async (req: DownloadUrlsRequest) => {
  try {
    const response = await api.post<DownloadDocuments[]>(
      '/api/documents/gen-download-urls',
      req
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned download urls ${axiosError.response?.data.message}`
    );
  }
};

export const streamDoToGDrive = async (req: StreamDoToGDriveRequest) => {
  try {
    const response = await api.post<StreamDoToGDriveResponse>(
      '/api/documents/stream-do-to-gdrive',
      req
    );
    return response;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to stream files from DigitalOcean to Google Drive with error: ${axiosError.response?.data.message}`
    );
  }
};

export const uploadToGoogleDrive = async (req: FormData) => {
  try {
    const response = await api.post('/api/documents/upload-to-google', req);
    return response;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to upload file to Google Drive with error: ${axiosError.response?.data.message}`
    );
  }
};

export const uploadUrls = async (req: UploadUrlsRequest) => {
  try {
    const response = await api.post<{ [key: string]: DocumentUploadUrlInfo }>(
      '/api/documents/gen-upload-urls',
      req
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned upload urls ${axiosError.response?.data.message}`
    );
  }
};

export const viewUrl = async (
  documentUuid: string,
  documentVersion?: number,
  triggerDownload?: boolean
) => {
  try {
    const queryParams = new URLSearchParams({
      ...(documentVersion && { documentVersion: documentVersion.toString() }),
      triggerDownload: triggerDownload ? 'true' : 'false',
    });
    const queryString = queryParams ? `?${queryParams.toString()}` : '';
    const response = await api.get<DocumentViewUrlInfo>(
      `/api/documents/gen-view-url/${documentUuid}${queryString}`
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned urls ${axiosError.response?.data.message}`
    );
  }
};

export const editDocument = async (
  documentUuid: string,
  req: UpdateDocumentRequest,
  version?: number
) => {
  try {
    const response = await api.patch(
      `/api/documents/${documentUuid}/update?version=${version}`,
      req
    );
    return response;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to edit document ${axiosError.response?.data.message}`
    );
  }
};

export const documentVersions = async (documentUuid: string) => {
  try {
    const response = await api.get<PgDocumentResponse[]>(
      `/api/documents/${documentUuid}/versions`
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to get document versions ${axiosError.response?.data.message}`
    );
  }
};

export const documentDetails = async (
  documentUuid: string,
  version?: number
) => {
  try {
    const response = await api.get<PgDocumentResponse>(
      `/api/documents/${documentUuid}?version=${version}`
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to get document details ${axiosError.response?.data.message}`
    );
  }
};

export const createDocument = async (
  projectUuid: string,
  req: CreateDocumentRequest
) => {
  try {
    const response = await api.post<PgDocument>(
      `/api/projects/${projectUuid}/documents/create`,
      req
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned upload urls ${axiosError.response?.data.message}`
    );
  }
};
