import { Textarea, TextareaProps } from '@mantine/core';
import { forwardRef, useContext } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

/**
 * @returns Mantine Textarea that defaults to size medium on small screen
 * sizes.
 */
export const CottageTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ ...props }: TextareaProps, ref) => {
    const { layout } = useContext(LayoutContext);
    return (
      <Textarea
        ref={ref}
        size={layout === LayoutType.MOBILE ? 'md' : undefined}
        {...props}
      />
    );
  }
);
CottageTextarea.displayName = 'CottageTextarea';
