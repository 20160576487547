import { Avatar, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { TbAlertCircle } from 'react-icons/tb';

type CottageInfo = {
  label: string;
};

// Because Mantine tooltips do not support multiple lines in the mouseover
// with any level of ease, this class aims to allow arbitrary lines of text
// separated with newlines to automatically format itself. This might be worth
// making the new CottageTooltip, but it looks different, so I don't want to do
// that refactor.
export const CottageInfo = ({ label, ...popoverProps }: CottageInfo) => {
  const [opened, { close, open }] = useDisclosure(false);

  const labels = label.split('\n');
  return (
    <Popover
      width="dropdown"
      position="bottom"
      withArrow
      opened={opened}
      {...popoverProps}
    >
      <Popover.Target>
        <Avatar
          radius="xl"
          size="xs"
          variant="transparent"
          c="grey"
          onMouseEnter={open}
          onMouseLeave={close}
          onTouchStart={open}
          onTouchCancel={close}
          onFocus={open}
          onBlur={close}
        >
          <TbAlertCircle size={14} />
        </Avatar>
      </Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: 'none', background: 'black' }}>
        {labels.map((label, index) => (
          <Text size="xs" c="white.0" key={`${index}${label}`}>
            {label}
          </Text>
        ))}
      </Popover.Dropdown>
    </Popover>
  );
};
