import { ActionIcon, Avatar, Group } from '@mantine/core';
import { BaseModalProps } from './BaseModal';
import { IconContext } from 'react-icons';
import { TbArrowLeft, TbX } from 'react-icons/tb';

type BaseModalHeaderProps = Pick<
  BaseModalProps,
  'icon' | 'iconColor' | 'onBack' | 'onClose' | 'withCloseButton'
>;

export const BaseModalHeader = ({
  icon,
  iconColor,
  onBack,
  onClose,
  withCloseButton,
}: BaseModalHeaderProps) => {
  const showFirstRow = withCloseButton || !!onBack || !!icon;

  // If back button is not shown, then show the icon on the first row (if icon
  // exists). Otherwise, show the icon on the second row (if icon exists)
  const showIconOnFirstRow = !!icon && !onBack;
  const showIconOnSecondRow = !!icon && !!onBack;

  const avatarIcon = (
    <Avatar size={68} radius={100} color={iconColor}>
      <IconContext.Provider value={{ size: '26px' }}>
        {icon}
      </IconContext.Provider>
    </Avatar>
  );

  return (
    <>
      {showFirstRow && (
        <Group position="apart" align="start">
          {!!onBack && (
            <Group position="left">
              <IconContext.Provider value={{ size: '24px' }}>
                <ActionIcon
                  variant="transparent"
                  onClick={onBack}
                  aria-label="back"
                >
                  <TbArrowLeft />
                </ActionIcon>
              </IconContext.Provider>
            </Group>
          )}
          {showIconOnFirstRow && avatarIcon}
          {withCloseButton && (
            <Group position="right" ml="auto">
              <IconContext.Provider value={{ size: '24px' }}>
                <ActionIcon
                  variant="transparent"
                  onClick={onClose}
                  aria-label="close"
                >
                  <TbX />
                </ActionIcon>
              </IconContext.Provider>
            </Group>
          )}
        </Group>
      )}
      {showIconOnSecondRow && avatarIcon}
    </>
  );
};
