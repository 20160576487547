import { createStyles } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

const useStyles = createStyles((theme) => ({
  root: {
    border: 'none',
  },
  content: {
    color: theme.colors[theme.primaryColor][9],
    fontSize: '14px',
    '.ProseMirror': {
      padding: '0px',
    },
  },
}));

type CottageRichTextEditorProps = {
  content: string;
  editable?: boolean;
};

export const CottageRichTextEditor = ({
  content,
  editable = false,
}: CottageRichTextEditorProps) => {
  const { classes } = useStyles();
  const editor = useEditor({
    editable,
    extensions: [StarterKit],
    content,
  });

  return (
    <RichTextEditor
      editor={editor}
      classNames={{
        root: classes.root,
        content: classes.content,
      }}
    >
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};
