import { ActionIcon, createStyles } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { TbHelpCircle } from 'react-icons/tb';

import { CottageButton } from '../shared/default';

type SupportButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'sm' | 'lg';
};

const useStyles = createStyles((theme) => ({
  supportTab: {
    color: 'white',
    marginRight: 0,
    borderRadius: '9999px', // This ensures left and right sides are rounded
    '&:hover': {
      backgroundColor: theme.colors.blue[8],
    },
  },
}));

export function SupportButton({ onClick, size = 'lg' }: SupportButtonProps) {
  const { classes } = useStyles();

  return size === 'sm' ? (
    <ActionIcon
      p="4px"
      size="lg"
      variant="filled"
      bg="blue"
      aria-label="Support"
      radius="xl"
      onClick={onClick}
    >
      <TbHelpCircle size={22} />
    </ActionIcon>
  ) : (
    <CottageButton className={classes.supportTab} ml="auto" onClick={onClick}>
      <TbHelpCircle /> &nbsp;Support
    </CottageButton>
  );
}
