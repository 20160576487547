import { Box } from '@mantine/core';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

type CottageMarkdownBoxProps = {
  markdown: string;
  children?: React.ReactNode;
};

export const CottageMarkdownBox = ({
  markdown,
  children,
}: CottageMarkdownBoxProps) => {
  return (
    <Box
      sx={() => ({
        '& h4': {
          color: 'rgba(13, 28, 64, 0.87)',
        },
        '& p': {
          fontSize: '14px',
          color: 'rgba(13, 28, 64, 0.87)',
        },
        '& ul': {
          listStylePosition: 'inside',
        },
      })}
    >
      {children}
      <ReactMarkdown remarkPlugins={[remarkBreaks]} components={{}}>
        {markdown || ''}
      </ReactMarkdown>
    </Box>
  );
};
