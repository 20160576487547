import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef, useContext } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

export type CottageNumberInputProps = NumberInputProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size' | 'onChange'>;

/**
 * @returns Mantine Input that defaults to size medium on small screen
 * sizes.
 */
export const CottageNumberInput = forwardRef<
  HTMLInputElement,
  CottageNumberInputProps
>(({ ...props }: CottageNumberInputProps, ref) => {
  const { layout } = useContext(LayoutContext);

  return (
    <NumberInput
      ref={ref}
      size={layout === LayoutType.MOBILE ? 'md' : undefined}
      {...props}
    />
  );
});
CottageNumberInput.displayName = 'CottageNumberInput';
