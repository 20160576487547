import { useContext } from 'react';

import { LayoutContext, LayoutType } from '../../contexts/LayoutContext';
import { CottageButton, CottageButtonProps } from '../';

type CottageAlertCtaButtonProps = CottageButtonProps;

export const CottageAlertCtaButton = (props: CottageAlertCtaButtonProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;

  const buttonProps: CottageButtonProps = isMobile
    ? {
        variant: 'outline',
        styles: (theme) => ({
          root: {
            backgroundColor: 'white',
            paddingLeft: theme.spacing.xxs,
            paddingRight: theme.spacing.xxs,
          },
        }),
      }
    : {
        variant: 'primary',
        styles: (theme) => ({
          root: {
            color: theme.colors.blue[9],
          },
        }),
      };

  return <CottageButton {...buttonProps} {...props} />;
};
