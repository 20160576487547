import { DocumentCategory } from '@prisma/client';

export const DOCUMENT_CATEGORY_TEXT: {
  [key: string]: string;
} = {
  CONCEPTUAL_DESIGN: 'Conceptual Design',
  SCHEMATIC_DESIGN: 'Schematic Design',
  DESIGN_DEVELOPMENT: 'Design Development',
  SPECS_AND_FINISHES: 'Specs and Finishes',
  PERMITTING: 'Permitting',
  CONSTRUCTION: 'Construction',
  CONSULTANT_DELIVERABLE: 'Consultant Deliverable',
  PROCUREMENT: 'Procurement',
  PROPOSAL_AND_CONTRACTS: 'Proposal and Contract',
} as const;

export const DOCUMENT_CATEGORY_BADGE_VARIANT: {
  [key: string]: string;
} = {
  CONCEPTUAL_DESIGN: 'blue',
  SCHEMATIC_DESIGN: 'blue',
  DESIGN_DEVELOPMENT: 'blue',
  SPECS_AND_FINISHES: 'blue',
  PERMITTING: 'olive',
  CONSTRUCTION: 'brown',
  CONSULTANT_DELIVERABLE: 'gray',
  PROCUREMENT: 'blueDark',
  PROPOSAL_AND_CONTRACTS: 'gray',
} as const;

export const DOCUMENT_CATEGORY_TEXT_TO_VALUE: {
  [key: string]: DocumentCategory;
} = {
  'Conceptual Design': DocumentCategory.CONCEPTUAL_DESIGN,
  'Schematic Design': DocumentCategory.SCHEMATIC_DESIGN,
  'Design Development': DocumentCategory.DESIGN_DEVELOPMENT,
  'Specs and Finishes': DocumentCategory.SPECS_AND_FINISHES,
  Permitting: DocumentCategory.PERMITTING,
  Construction: DocumentCategory.CONSTRUCTION,
  'Consultant Deliverable': DocumentCategory.CONSULTANT_DELIVERABLE,
  Procurement: DocumentCategory.PROCUREMENT,
  'Proposal and Contract': DocumentCategory.PROPOSAL_AND_CONTRACTS,
} as const;
